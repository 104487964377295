namespace eh {

  export class QuickLinksController  {
      public static MODULE_WRAPPER: string = 'ehel-quick-links';
      /* Quicklinks */
      public static QUICKLINKS_WRAPPER_CLASSNAME: string = 'ehel-quick-links--links-wrapper';
      /* Table of content */
      public static TABLE_OF_CONTENT_ELEM: string = 'ehel-table-of-content';
      
      static init($base: JQuery<HTMLElement>): void {
        $(`.${QuickLinksController.MODULE_WRAPPER}`, $base).each((index: number, element: HTMLElement) => {
          new QuickLinksController(element);
        });
      }
  
      private quickLinksWrapper: HTMLElement | null | undefined;

      private tableOfContent: HTMLElement | null | undefined;

      private vm: QuickLinksViewModel | null | undefined = null;

      constructor(private readonly el: HTMLElement) {

        this.quickLinksWrapper = this.el.querySelector(`.${QuickLinksController.QUICKLINKS_WRAPPER_CLASSNAME}`) as HTMLElement;
        this.tableOfContent = this.el.querySelector(`.${QuickLinksController.TABLE_OF_CONTENT_ELEM}`) as HTMLElement;
        this.vm = new QuickLinksViewModel(this.el, this.quickLinksWrapper, this.tableOfContent);
        this.init();
      }

      private init(): void {
          this.registerControls();
      }

      private registerControls(): void {
      
        document.addEventListener('scroll', this.onScrollChange, {
          passive: true,
        });
        eh.Breakpoints.getInstance().registerChangeListener(() => {
          setTimeout(() => {
            this.onScrollChange();
          }, 100);
        });
      }

      private onScrollChange: () => void = (): void => {
        this.vm?.scrollChange();
      };

  }


  class QuickLinksViewModel {

      private static GRID_CELL_CLASSNAME: string = 'ehel-grid--cell';
      private static ANCHOR_NAVIGATION_CLASSNAME: string = 'ehel-anchor-navigation';
      private static AVOID_STICKY_CLASSNAME: string = 'avoid-sticky';
      private static QUICK_LINKS_STICKY_CLASSNAME: string = 'ehel-quick-links-sticky';
      private static QUICK_LINKS_LINKS_CLASSNAME: string = 'ehel-quick-links--links';
  
      private root: HTMLElement = document.scrollingElement as HTMLElement;

      private quickLinksGridCell!: HTMLElement | null;
      private anchorNavigation!: HTMLElement | null;
      private quickLinksLinks!: HTMLElement | null;

      private offset: number = 60;
      private anchorOffset: number = 64;

      private quickLinkInitialTop = 0;

      constructor(
        private module: HTMLElement,
        private quickLinks: HTMLElement | null | undefined,
        private tableOfContent: HTMLElement | null | undefined) { 
          setTimeout(() => {
              this.init();
              }, 20);
      }
      private init(): void {
        if (this.quickLinks) {
          this.quickLinksGridCell = this.quickLinks.closest(`.${QuickLinksViewModel.GRID_CELL_CLASSNAME}`);
          this.quickLinksLinks = this.quickLinks.closest(`.${QuickLinksViewModel.QUICK_LINKS_LINKS_CLASSNAME}`);
        }
        if (this.tableOfContent) {
          this.anchorNavigation = this.tableOfContent.closest(`.${QuickLinksViewModel.ANCHOR_NAVIGATION_CLASSNAME}`);
        }
        if (this.quickLinks) {
          const quickLinksGridCellParent: HTMLElement | undefined = this.quickLinksGridCell?.parentElement as HTMLElement;
          if (this.quickLinksGridCell) {
            this.quickLinkInitialTop = (this.tableOfContent ? (this.tableOfContent.offsetHeight + this.offset) : 0) + (quickLinksGridCellParent?.offsetTop || 0) - this.quickLinksGridCell.offsetTop;
            this.quickLinks.style.top = `${this.quickLinkInitialTop}px`;
          }
        }
  
      }

      public scrollChange() {
        if (!eh.Breakpoints.getInstance().isMobile) {
          const moduleHeight = this.module.offsetHeight;
            if (!this.tableOfContent && this.quickLinks) {
  
            const wrapperHeight = this.quickLinks.offsetHeight;
      
            const scrollingAmount = this.root.scrollTop - this.module.offsetTop;
      
            if (scrollingAmount > 0) {
              if (scrollingAmount + wrapperHeight < moduleHeight) {
                // this.quickLinks.style.top = `${scrollingAmount + this.quickLinkInitialTop}px`;
                this.quickLinks.style.top = '0px';
                if (this.quickLinksLinks) {
                  this.quickLinksLinks.classList.add(QuickLinksViewModel.QUICK_LINKS_STICKY_CLASSNAME);
  
                  const parentStyle = window.getComputedStyle(this.quickLinksLinks.parentElement as HTMLElement);
  
                  this.quickLinksLinks.style.width = `${(this.quickLinksLinks.parentElement?.clientWidth || 0) - parseFloat(parentStyle.paddingLeft) - parseFloat(parentStyle.paddingRight)}px`;
                  this.quickLinksLinks.style.height = `${(this.quickLinksLinks.parentElement?.clientWidth || 0) - parseFloat(parentStyle.paddingTop) - parseFloat(parentStyle.paddingBottom)}px`;
                }
      
              } else {
                this.quickLinks.style.top = `${this.quickLinkInitialTop}px`;
                if (this.quickLinksLinks) {
                  this.quickLinksLinks.classList.remove(QuickLinksViewModel.QUICK_LINKS_STICKY_CLASSNAME);
                  this.quickLinksLinks.style.width = 'auto';
                  this.quickLinksLinks.style.height = 'auto';
                }
              }
            } else {
              this.quickLinks.style.top = `${this.quickLinkInitialTop}px`;
              if (this.quickLinksLinks) {
                this.quickLinksLinks.classList.remove(QuickLinksViewModel.QUICK_LINKS_STICKY_CLASSNAME);
                this.quickLinksLinks.style.width = 'auto';
                this.quickLinksLinks.style.height = 'auto';
              }
            }
            if (this.quickLinksGridCell) {
              this.quickLinksGridCell.style.height = `${this.quickLinks.offsetHeight}px`;
            }
          } else if (this.tableOfContent) {
            const thresholdEndTableOfContent = this.tableOfContent.getBoundingClientRect().top + this.tableOfContent.offsetHeight;
            const thresholdQuickLinks = ((this.quickLinks?.getBoundingClientRect().top || 0) + (this.quickLinks?.offsetHeight || 0)) || ((this.anchorNavigation?.parentElement?.getBoundingClientRect().top || 0) - 104) ;

            if (thresholdEndTableOfContent <= 0 && this.quickLinks) {
              this.tableOfContent.style.opacity = '0';
            } else if (thresholdQuickLinks > 0) {
              this.tableOfContent.style.opacity = '1';
              this.tableOfContent.style.top = `0px`;
            }
            if (this.anchorNavigation) {
              this.anchorNavigation.style.width = '';
              this.anchorNavigation.style.height = '';
            }
  
            if (thresholdQuickLinks <= 0) {
              this.tableOfContent.style.opacity = '1';
              const scrollingAmount = Math.max(this.root.scrollTop - this.module.offsetTop - this.anchorOffset, 0);
              if (scrollingAmount + this.tableOfContent.offsetHeight < moduleHeight) {
                if (this.anchorNavigation) {
                    this.anchorNavigation.classList.add(QuickLinksViewModel.AVOID_STICKY_CLASSNAME);
                    this.anchorNavigation.style.width = `${this.anchorNavigation.parentElement?.clientWidth}px`;
                }
              } else {
                  this.anchorNavigation?.classList.remove(QuickLinksViewModel.AVOID_STICKY_CLASSNAME);
              }
            } else {
              if (this.anchorNavigation) {
                this.anchorNavigation.classList.remove(QuickLinksViewModel.AVOID_STICKY_CLASSNAME);
              }
            }
          }
        } else {
        
          if (this.tableOfContent) {
            if (this.anchorNavigation) {
              this.anchorNavigation.classList.remove(QuickLinksViewModel.AVOID_STICKY_CLASSNAME);
              this.anchorNavigation.style.width = '';
              this.anchorNavigation.style.height = '';
            }
          } else if (this.quickLinks) {
            if (this.quickLinksGridCell) {
              this.quickLinksGridCell.style.height = '';
            }
          }
  
        }
      }
  }
}
