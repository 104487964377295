$(() => {
  
  const $root = $(':root');
  $root.on(cs.Snippet.EventIdPostReplace, ($event: cs.SnippetEventPostReplace) => {
    let target = $event.replacedTarget
    if (!target && $event.originalEvent) {
      const oEvent = ($event.originalEvent as any as cs.SnippetEventPostReplace | null)
      if (oEvent?.replacedTarget) {
        target = oEvent.replacedTarget;
      }
    }
    if (target){
      eh.Composing.init($(target), true);
    }
  });
  eh.Composing.init($root);
  
});

namespace eh {
  
  export class Composing {
    
    static init($base: JQuery<HTMLElement>, isSnippetRequest = false): void {
      
      eh.EhAnchorNavigationController.init($base);
      eh.EhCompactTeaserController.init($base);
      eh.EhGalleryController.ehInit($base);
      eh.EhAccordionController.init($base);
      eh.EhProductTeaserLinkTabController.init($base);
      eh.EhLoadMoreController.init($base);
      eh.EhFilterController.ehInit($base, isSnippetRequest);
      eh.Footnotes.ehInit($base, isSnippetRequest);
      
    }
  }
}
