namespace eh {
    /**
     * Controller to manage comopact-teaser behaviour. 
     */
    export class EhCompactTeaserController {

        static init($base: JQuery<HTMLElement>): void {
            $base.selfOrFind(`.${EhCompactTeaserController.COMPACT_TEASSER_WRAPPER_CLASSNAME}`).each((index: number, element: HTMLElement) => {
              new EhCompactTeaserController(element);
            });
          }
        
        public static COMPACT_TEASSER_WRAPPER_CLASSNAME: string = 'ehel-compact-teaser--wrapper';
        public static COMPACT_TEASER_CLASSNAME: string = 'ehel-compact-teaser';
        public static COMPACT_TEASER_CELL_CLASSNAME: string = 'ehel-compact-teaser--teaser-cell';

        private el: HTMLElement | null | undefined;
        private elements: NodeListOf<HTMLElement> | undefined;

        private vm: EhCompactTeaserViewModel;

        constructor(
            private readonly base: HTMLElement | undefined | null,
        ) {
            this.elements = this.base?.querySelectorAll(`.${EhCompactTeaserController.COMPACT_TEASER_CELL_CLASSNAME}`);
            this.vm = new EhCompactTeaserViewModel(this.elements);
            
            this.init();
        }

        private init(): void {
            this.vm.setEqualHeights();
        }

    }


    class EhCompactTeaserViewModel {

        private static HIDE_CLASSNAME: string = 'ehtw-hidden';

        constructor(private elements: NodeListOf<HTMLElement> | undefined,
        ) {
            this.init();
        }


        private init(): void {
            eh.Breakpoints.getInstance().registerResizeListener(this.setEqualHeights);
        }
    
        public setEqualHeights() {
            let maxHeight = 0;
            this.elements?.forEach(el => {
                if(el.offsetHeight > maxHeight)
                    maxHeight = el.offsetHeight;
                });
        
            this.elements?.forEach(el => {
                el.style.height = `${maxHeight}px`;
            });
        }
            
    }
}