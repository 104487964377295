namespace eh {

  /**
   * Controller to manage load more button behaviour (product teaser floating). 
   */
  export class EhLoadMoreController {

      public static RELOAD_EVENT: string = 'EhLoadMoreController:reload';

      public static LOAD_MORE_CLASSNAME: string = 'ehel-load-more';

      public static LOAD_MORE_SECTION_CLASSNAME: string = 'ehel-load-more--section';

      public static LOAD_MORE_BUTTON_CLASSNAME: string = 'ehel-load-more--button';

      public static PRODUCT_CARD_CLASSNAME: string = 'ehts-result-card';

      public static PROGRESS_BAR_CLASSNAME: string = 'ehel-progress-bar';

      public static PROGRESS_LINE_CLASSNAME: string = 'ehel-progress-line';

      public static VIEWED_PRODUCTS_CLASSNAME: string = 'ehel-viewed-products'

      public static CLOSED_CLASSNAME: string = 'ehel-load-more--closed';
      
      static init($base: JQuery<HTMLElement>): void {
        $(`.${EhLoadMoreController.LOAD_MORE_CLASSNAME}`, $base).each((index: number, element: HTMLElement) => {
          new EhLoadMoreController(element);
        });
      }

      private el: HTMLElement | null | undefined;
      private buttonMore: HTMLElement | null | undefined;
      private productCards: NodeListOf<HTMLElement> | null | undefined;
      private progressBar: HTMLElement | null | undefined;
      private progressLine: HTMLElement | null | undefined;
      private viewedProducts: HTMLElement | null | undefined;
      private vm: EhLoadMoreViewModel;
      private totalResults = 1;
      private nextPage:string|undefined;
      private nextPageHistory:string|undefined;
      private loadMoreSection: HTMLElement | null | undefined;

      constructor(
          private readonly base: HTMLElement | null,
      ) {

          this.el = this.base;
          const that = this;
          this.buttonMore = this.el?.querySelector(`.${EhLoadMoreController.LOAD_MORE_BUTTON_CLASSNAME}`);
          this.productCards = this.el?.querySelectorAll(`.${EhLoadMoreController.PRODUCT_CARD_CLASSNAME}`);
          this.totalResults = (this.el?.dataset.resultCount ? (Number.parseInt(this.el?.dataset.resultCount)) : this.productCards?.length) || this.productCards?.length || 0;
          this.nextPage = this.el?.dataset.nextPage;
          this.nextPageHistory = this.el?.dataset.nextPageHistory;
          this.viewedProducts = this.el?.querySelector(`.${EhLoadMoreController.VIEWED_PRODUCTS_CLASSNAME}`);
          this.progressBar = this.el?.querySelector(`.${EhLoadMoreController.PROGRESS_BAR_CLASSNAME}`);
          this.progressLine = this.progressBar?.querySelector(`.${EhLoadMoreController.PROGRESS_LINE_CLASSNAME}`);
          this.loadMoreSection = this.el?.querySelector(`.${EhLoadMoreController.LOAD_MORE_SECTION_CLASSNAME}`);
          

          if (!this.buttonMore || !this.el || !this.productCards || !this.viewedProducts || !this.progressLine ) {
              throw new Error(`Missing required element`);
          }
          this.onButtonMoreClicked.bind(this);
          this.reload.bind(this);

          this.vm = new EhLoadMoreViewModel(
              this.el,
              this.buttonMore,
              () => this.productCards,
              () => this.totalResults,
              this.viewedProducts,
              this.progressLine,
              this.loadMoreSection,
              () => this.nextPage,
              () => this.nextPageHistory,
              () => this.refresh()
          );
          this.init();
          this.el?.addEventListener(EhLoadMoreController.RELOAD_EVENT, (e) => {
              //console.log("event: ",e );
              that.reload();
          })

      }

      private refresh(): void{
          this.productCards = this.el?.querySelectorAll(`.${EhLoadMoreController.PRODUCT_CARD_CLASSNAME}`);
          this.totalResults = (this.el?.dataset.resultCount ? (Number.parseInt(this.el?.dataset.resultCount)) : this.productCards?.length) || this.productCards?.length || 0;
          this.nextPage = this.el?.dataset.nextPage;
          this.nextPageHistory = this.el?.dataset.nextPageHistory;
      }

      private reload(): void {
          this.refresh();
          this.vm?.resetPage();
          this.vm?.showProducts();
      }

      private init(): void {
          this.registerControls();
      }

      private registerControls(): void {
          this.buttonMore?.addEventListener('click', this.onButtonMoreClicked);
      }

      private onButtonMoreClicked = (e: MouseEvent | TouchEvent): void => {
          e.preventDefault();
          this.vm.loadMore();
      }
  }


  class EhLoadMoreViewModel {

      private static HIDE_CLASSNAME: string = 'ehtw-hidden';
      public static BREAKPOINT_X_LARGE: number = 1440;
      public static BREAKPOINT_LARGE: number = 1025;
      public static BREAKPOINT_MEDIUM: number = 769;
      public static BREAKPOINT_SMALL: number = 481;
      public static BREAKPOINT_X_SMALL: number = 350;

      private pageSize = 0;
      private currentPage: number = 1;
      private textMore:string = $(this.buttonMore).data('labelMoreText') || "You viewed {{numberShown}} of {{numberOverall}} products";
      private textCompleted:string = $(this.buttonMore).data('labelCompleteText') || "You viewed all {{numberOverall}} of {{numberOverall}} products";
      private loadAmounts: string[];

      constructor(
          private readonly loadMoreElement: HTMLElement,
          private readonly buttonMore: HTMLElement,
          private readonly productCards: () => NodeListOf<HTMLElement> | null |undefined,
          private readonly totalResults: () => number,
          private readonly viewedProducts: HTMLElement,
          private readonly progressLine: HTMLElement,
          private readonly loadMoreSection: HTMLElement| null | undefined,
          private readonly nextPage: () => string|undefined,
          private readonly nextPageHistory: () => string|undefined,
          private readonly refresh: () => void
      ) {
          this.currentPage = loadMoreElement.dataset.page ? Number.parseInt(loadMoreElement.dataset.page) : this.currentPage || this.currentPage;
          this.showProducts.bind(this);
          this.doShowProducts.bind(this);
          this.loadMore.bind(this);
          this.onBreakpointChange.bind(this);
          if($(loadMoreElement).data('loadnumbers')) {
            this.loadAmounts = $(this.loadMoreElement).data('loadnumbers').split(',');
          }
          this.init();
      }


      private init(): void {
          eh.Breakpoints.getInstance().registerChangeListener(this.onBreakpointChange);
      }

      public resetPage(): void {
          this.currentPage = 1;
      }

      private onBreakpointChange: (old: Breakpoint | null, current: Breakpoint) => void = (_old: Breakpoint | null, _current: Breakpoint): void => {
          let change: boolean = false;
          let amount: number; 
          switch (_current.threshold) {
              case EhLoadMoreViewModel.BREAKPOINT_X_LARGE: {
                amount = 8;
                if(this.loadAmounts && this.loadAmounts.length > 0) {
                  amount = parseInt(this.loadAmounts[0]);
                }
                change = this.updatePageSize(amount);
                break;
              }
              case EhLoadMoreViewModel.BREAKPOINT_LARGE: {
                amount = 6;
                if(this.loadAmounts && this.loadAmounts.length > 1) {
                  amount = parseInt(this.loadAmounts[1]);
                }
                change = this.updatePageSize(amount);
                  break;
              }
              case EhLoadMoreViewModel.BREAKPOINT_MEDIUM: {
                amount = 8;
                if(this.loadAmounts && this.loadAmounts.length > 2) {
                  amount = parseInt(this.loadAmounts[2]);
                }
                change = this.updatePageSize(amount);
                break;
              }
              case EhLoadMoreViewModel.BREAKPOINT_SMALL: {
                amount = 4;
                if(this.loadAmounts && this.loadAmounts.length > 3) {
                  amount = parseInt(this.loadAmounts[3]);
                }
                change = this.updatePageSize(amount);
                break;
              }
              case EhLoadMoreViewModel.BREAKPOINT_X_SMALL: {
                amount = 4;
                if(this.loadAmounts && this.loadAmounts.length > 4) {
                  amount = parseInt(this.loadAmounts[4]);
                }
                change = this.updatePageSize(amount);
                break;
              }
              default: {
                amount = 4;
                if(this.loadAmounts && this.loadAmounts.length > 5) {
                  amount = parseInt(this.loadAmounts[5]);
                }
                change = this.updatePageSize(amount);
                break;
              }
          }
          if (change) {
              this.showProducts();
          }
      };

       private updatePageSize:(newSize: number) => boolean = (newSize) => {
           const rv = this.pageSize!==newSize;
           this.pageSize = newSize;
           return rv;
       }
  
      public loadMore() {
          this.currentPage++;
          this.showProducts();
      }

      public showProducts() {
          this.loadMoreElement.classList.remove(EhLoadMoreController.CLOSED_CLASSNAME);
          this.buttonMore.blur();
          const moreCardsNeeded:boolean= (this.productCards()?.length || 0) < this.currentPage * this.pageSize;
          const pagingExceeded = (this.currentPage * this.pageSize) - this.totalResults() > this.pageSize
          if ( moreCardsNeeded && !pagingExceeded) {
              const url = this.nextPage();
              const historyUrl = this.nextPageHistory();
              if (url) {
                  this.buttonMore.classList.add("loading");
                  $.ajax(url, {method: "POST"}).done((d: string) => {
                      const newDom = new DOMParser().parseFromString(d, "text/html");
                      const s: HTMLElement | null = newDom.querySelector(`.${EhLoadMoreController.LOAD_MORE_CLASSNAME}`);
                      if (s) {
                          this.loadMoreElement.dataset.page = s.dataset.page
                          this.loadMoreElement.dataset.nextPage = s.dataset.nextPage
                          this.loadMoreElement.dataset.nextPageHistory = s.dataset.nextPageHistory
                          this.loadMoreElement.dataset.resultCount = s.dataset.resultCount
                      }
                      let parent:HTMLElement|null = null;
                      this.productCards()?.forEach((e) => {
                          parent = e.parentElement;
                          if (parent) {
                              return false;
                          }
                          return true;
                      });
                      if (parent) {
                          newDom.querySelectorAll(`.${EhLoadMoreController.LOAD_MORE_CLASSNAME} .${EhLoadMoreController.PRODUCT_CARD_CLASSNAME}`).forEach((e: HTMLElement) => {
                              parent?.appendChild(e);
                              const ev: any = new Event(cs.Snippet.EventIdPostReplace);
                              ev.replacedTarget = e;
                              document.querySelector(':root')?.dispatchEvent(ev);
                          });
                      }
                      this.refresh();
                      this.doShowProducts();
                      if (historyUrl) {
                          history.pushState({}, "", historyUrl);
                      }
                      this.buttonMore.classList.remove("loading");

                  });
              } else {
                this.doShowProducts();
              }
          } else {
              this.doShowProducts();
          }
      }

      private doShowProducts() {
          let index = 0;
          let shownElements = 0;
          const hideOnFirstPage:boolean= (this.productCards()?.length || 0) <= this.pageSize && this.currentPage === 1;
          if (!this.productCards) {
              console.log("this: ", this);
          }
          this.productCards()?.forEach(product => {
              if (index < this.currentPage * this.pageSize) {
                  shownElements++;
                  product.classList.remove(EhLoadMoreViewModel.HIDE_CLASSNAME);
              } else {
                  product.classList.add(EhLoadMoreViewModel.HIDE_CLASSNAME);
              }
              index++;
          });

          this.viewedProducts.innerText = this.textMore.replace(/{{numberShown}}/gi, ""+shownElements).replace(/{{numberOverall}}/gi, ""+this.totalResults());
          this.progressLine.setAttribute('x2', (shownElements * 100 / Math.max(this.totalResults(), 1)).toString());
          if (shownElements >= this.totalResults()) {
              this.buttonMore.classList.add(EhLoadMoreViewModel.HIDE_CLASSNAME);
              this.viewedProducts.innerText = this.textCompleted.replace(/{{numberOverall}}/gi, ""+this.totalResults());
          } else {
              this.buttonMore.classList.remove(EhLoadMoreViewModel.HIDE_CLASSNAME);
          }

          if (hideOnFirstPage) {
            this.loadMoreSection?.classList.add(EhLoadMoreViewModel.HIDE_CLASSNAME);
          }
      }
  }
}