namespace eh {
/**
 * Controller to manage anchor-navigation behaviour. 
 */
export class EhGalleryController {

    public static GALLERY_CLASSNAME: string = 'ehel-gallery';
    public static GALLERY_LIGHTBOX_CLASSNAME: string = 'ehel-gallery-lighbox';
    public static GALLERY_NAVIGATION_CLASSNAME: string = 'ehel-gallery--navigation';
    public static GALLERY_NAVIGATION_ITEM_CLASSNAME: string = 'ehel-gallery--indicator';

    public static GALLERY_PREVIOUS_BTN_CLASSNAME: string = 'ehel-gallery--prev-btn';
    public static GALLERY_NEXT_BTN_CLASSNAME: string = 'ehel-gallery--next-btn';

    public static GALLERY_PCITURE_ITEM_CLASSNAME: string = 'ehel-gallery--picture';
    public static GALLERY_PCITURE_TEXT_ITEM_CLASSNAME: string = 'ehel-gallery--picture-text';

    static ehInit($base: JQuery<HTMLElement>): void {
        $(`.${EhGalleryController.GALLERY_CLASSNAME}`, $base).each((index: number, element: HTMLElement) => {
            new EhGalleryController(element);
        });
    }

    private el: HTMLElement | null | undefined;
    private lightbox: HTMLElement | null | undefined;
    private navigation: HTMLElement | null | undefined;
    private navigationLightbox: HTMLElement | null | undefined;
    private navigationItems: NodeListOf<HTMLElement> | undefined;

    private previousBtn: NodeListOf<HTMLElement> | undefined;
    private nextBtn: NodeListOf<HTMLElement> | undefined;
    private vm: EhGalleryViewModel;

    private touchstartX: number = 0;
    private touchendX: number = 0;

    constructor(
        private readonly base: HTMLElement | null,
    ) {

        this.el = this.base;//?.querySelector(`.${EhGalleryController.GALLERY_CLASSNAME}`);
        this.navigation = this.el?.querySelector(`.${EhGalleryController.GALLERY_NAVIGATION_CLASSNAME}`);
        this.lightbox = this.el?.querySelector(`.${EhGalleryController.GALLERY_LIGHTBOX_CLASSNAME}`);
        this.navigationLightbox = this.lightbox?.querySelector(`.${EhGalleryController.GALLERY_NAVIGATION_CLASSNAME}`);

        this.navigationItems = this.el?.querySelectorAll(`.${EhGalleryController.GALLERY_NAVIGATION_ITEM_CLASSNAME}`);
        this.previousBtn = this.el?.querySelectorAll(`.${EhGalleryController.GALLERY_PREVIOUS_BTN_CLASSNAME}`);
        this.nextBtn = this.el?.querySelectorAll(`.${EhGalleryController.GALLERY_NEXT_BTN_CLASSNAME}`);
      
        if (!this.navigation || !this.el) {
            //throw new Error(`Missing required element`);
            return;
        }

        this.vm = new EhGalleryViewModel(
            this.el,
            this.lightbox,
            this.navigation, 
            this.navigationLightbox,
            this.navigationItems,
            this.previousBtn,
            this.nextBtn,
        );
        
        if (!this.vm.isDisabled) {
            this.init();
        }
    }

    private init(): void {
        this.registerControls();
    }

    private registerControls(): void {
      this.previousBtn?.forEach((item: HTMLElement): void => {
        item.addEventListener('click', this.onPreviousClicked);
      });

      this.nextBtn?.forEach((item: HTMLElement): void => {
        item.addEventListener('click', this.onNextClicked);
      });

      this.navigationItems?.forEach((item: HTMLElement): void => {
          item.addEventListener('click', this.onItemClicked);
      });
      this.lightbox?.addEventListener('touchstart', this.onTouchStart);
      this.lightbox?.addEventListener('touchend', this.onTouchEnd);
    }

    private onTouchStart = (e: TouchEvent): void => {
      this.touchstartX = e.changedTouches[0].screenX;
    }

    private onTouchEnd = (e: TouchEvent): void => {
      this.touchendX = e.changedTouches[0].screenX;
      if (this.touchendX < this.touchstartX) {
          this.vm.next();
      } else if (this.touchendX > this.touchstartX) {
          this.vm.previous();
      }
    }

    private onItemClicked = (e: MouseEvent | TouchEvent): void => {
        e.preventDefault();
        this.vm.setCurrentItem(e.currentTarget as HTMLElement);
    }

    private onPreviousClicked = (e: MouseEvent | TouchEvent): void => {
        e.preventDefault();
        (document?.activeElement as HTMLElement).blur();
        this.vm.previous();
    }

    private onNextClicked = (e: MouseEvent | TouchEvent): void => {
        e.preventDefault();
        (document?.activeElement as HTMLElement).blur();
        this.vm.next();
    }
}

interface IScrollerItem {
    idx: number;
    link: HTMLElement;
    target: HTMLElement;
    targetText: HTMLElement;
    lightboxLink?: HTMLElement | null;
    lightboxTarget?: HTMLElement | null;
    lightboxTargetText?: HTMLElement | null;
    // thresholdBegin: number;
    // thresholdEnd: number;
}

class EhGalleryViewModel {

    public isDisabled: boolean = false;

    private static ACTIVE_CLASSNAME: string = 'is-active';
    private static DISABLED_CLASSNAME: string = 'is-disabled';
    private static HIDE_CLASSNAME: string = 'ehtw-hidden';
    private static TRANSITION_EASING_LINEAR: string = 'linear';
    private static TRANSITION_DURATION: number = 400;
    private static MIN_AMOUNT_SCROLL_ITEMS: number = 1;
    private _currentItem: IScrollerItem | undefined;
    private _scrollerItems: IScrollerItem[] = [];
    
    private isVertical = true;

    constructor(
        private gallery: HTMLElement,
        private lightbox: HTMLElement | null | undefined,
        private scroller: HTMLElement,
        private scrollerLightbox: HTMLElement | null | undefined,
        private items: NodeListOf<HTMLElement> | undefined,
        private previousButton: NodeListOf<HTMLElement> | undefined,
        private nextButton: NodeListOf<HTMLElement> | undefined,
    ) {
        this.init();
    }

    public previous(): void {
        if (!this._currentItem) {
            return this.setCurrentItem(this.getItemByIdx(0).link);
        }
        const targetIndex: number = Math.max(0, this._currentItem.idx - 1)
        const previousItem: IScrollerItem = this.getItemByIdx(targetIndex);
        this.setCurrentItem(previousItem.link);
    }
    
    public next(): void {
        if (!this._currentItem) {
            return this.setCurrentItem(this.getItemByIdx(0).link);
        }
        const targetIndex: number = Math.min(this._scrollerItems.length - 1, this._currentItem.idx + 1);
        const nextItem: IScrollerItem = this.getItemByIdx(targetIndex);
        this.setCurrentItem(nextItem.link);
    }

    public setCurrentItem(item: HTMLElement | undefined, triggerJumpToAnchor: boolean = true): void {
        if (this._currentItem) {
          this.notifyLayerState(false);
          this._currentItem.target.classList.add(EhGalleryViewModel.HIDE_CLASSNAME);
          this._currentItem.targetText.classList.add(EhGalleryViewModel.HIDE_CLASSNAME);
          this._currentItem.lightboxTarget?.classList.add(EhGalleryViewModel.HIDE_CLASSNAME);
          this._currentItem.lightboxTargetText?.classList.add(EhGalleryViewModel.HIDE_CLASSNAME);
          this._currentItem.link.classList.remove(EhGalleryViewModel.ACTIVE_CLASSNAME);
          this._currentItem.lightboxLink?.classList.remove(EhGalleryViewModel.ACTIVE_CLASSNAME);
          this._currentItem.link.blur();
          this._currentItem.lightboxLink?.blur();
        }
        this._currentItem = this.getItemByEl(item);
        this._currentItem?.link.classList.add(EhGalleryViewModel.ACTIVE_CLASSNAME);
        this._currentItem?.target.classList.remove(EhGalleryViewModel.HIDE_CLASSNAME);
        this._currentItem?.targetText.classList.remove(EhGalleryViewModel.HIDE_CLASSNAME);

        this._currentItem?.lightboxLink?.classList.add(EhGalleryViewModel.ACTIVE_CLASSNAME);
        this._currentItem?.lightboxTarget?.classList.remove(EhGalleryViewModel.HIDE_CLASSNAME);
        this._currentItem?.lightboxTargetText?.classList.remove(EhGalleryViewModel.HIDE_CLASSNAME);
        if(this._currentItem) {
          //this.notifyLayerState(true);
        }

        let index = this._scrollerItems.findIndex(si => si.link === item);
        if (index === 0) {
          this.previousButton?.forEach(item => {
              item.classList.add(EhGalleryViewModel.DISABLED_CLASSNAME);
          });
        } else {
          this.previousButton?.forEach(item => {
              item.classList.remove(EhGalleryViewModel.DISABLED_CLASSNAME);
          });
        }

        if (index === this._scrollerItems.length - 1) {
            this.nextButton?.forEach(item => {
                item.classList.add(EhGalleryViewModel.DISABLED_CLASSNAME);
            });
        } else {
            this.nextButton?.forEach(item => {
                item.classList.remove(EhGalleryViewModel.DISABLED_CLASSNAME);
            });
        }
        //  this.invalidateControls();
        this.alignItemToViewport(this._currentItem?.lightboxLink, this.scrollerLightbox);
        this.alignItemToViewport(this._currentItem?.link, this.scroller);
    }

    private init(): void {
        this.invalidateItems();
        if (this._scrollerItems.length < EhGalleryViewModel.MIN_AMOUNT_SCROLL_ITEMS) {
            this.isDisabled = true;
            this.scroller.classList.add(EhGalleryViewModel.HIDE_CLASSNAME);
            this._scrollerItems = [];
            return;
        }
        setTimeout(() => {
            this.invalidateControls(this.scroller);
        }, 0);

        this.setCurrentItem(this.getItemByIdx(0).link);
        eh.Breakpoints.getInstance().registerChangeListener(this.onBreakpointChange);
        if (this.lightbox) {
          new ResizeObserver(() => {
              if (this.lightbox?.clientWidth && this.scrollerLightbox) {
                  this.alignItemToViewport(this._currentItem?.lightboxLink, this.scrollerLightbox);
                  this.invalidateControls(this.scrollerLightbox as HTMLElement);
                  this.invalidateControls(this.scroller);
              }
          }).observe(this.lightbox);
      }
    }

    private notifyLayerState(isOpen:boolean) {
      // inform video play/pause
      if(this._currentItem?.lightboxTarget) {
        $(this._currentItem?.lightboxTarget).selfOrFind(`.${OVERLAY_EVENTS.EVENT_NODE_CLASS}`).trigger(OVERLAY_EVENTS.LAYER_STATE_EVENT, {
          'state': isOpen? OVERLAY_EVENTS.LAYER_STATE_OPENED : OVERLAY_EVENTS.LAYER_STATE_CLOSED
        });
      }
      if(this._currentItem?.target) {
        $(this._currentItem?.target).selfOrFind(`.${OVERLAY_EVENTS.EVENT_NODE_CLASS}`).trigger(OVERLAY_EVENTS.LAYER_STATE_EVENT, {
          'state': isOpen? OVERLAY_EVENTS.LAYER_STATE_OPENED : OVERLAY_EVENTS.LAYER_STATE_CLOSED
        });
      }
    }

    private onBreakpointChange: (old: eh.Breakpoint | null, current: eh.Breakpoint) => void = (_old: eh.Breakpoint | null, _current: eh.Breakpoint): void => {
        this.isVertical = !_current.isInRange(768);
    };

   private alignItemToViewport(elem: HTMLElement | null | undefined, scroller: HTMLElement | null | undefined): void {
    if (!elem || !scroller || scroller.clientWidth === 0) {
        return;
    }

    if (this.isVertical) {
      const scrollerPos: number = scroller.scrollTop;
      let clipping: number = elem.offsetTop - scrollerPos;
      const targetScrollPosTop = scrollerPos + clipping;

      if (scroller.scrollTop !== targetScrollPosTop) {
        $(scroller).animate({
            scrollTop: targetScrollPosTop
        },
        EhGalleryViewModel.TRANSITION_DURATION,
        EhGalleryViewModel.TRANSITION_EASING_LINEAR);
      }
    } else {
      const scrollerPos: number = scroller.scrollLeft;
      let clipping: number = elem.offsetLeft -  scrollerPos;
      const targetScrollPosLeft = scrollerPos + clipping;
      if (scroller.scrollLeft !== targetScrollPosLeft) {
        $(scroller).animate({
          scrollLeft: targetScrollPosLeft
        },
        EhGalleryViewModel.TRANSITION_DURATION,
        EhGalleryViewModel.TRANSITION_EASING_LINEAR);
      }
    }
  }

    private getItemByEl(el: HTMLElement | undefined): IScrollerItem | undefined {
      return this._scrollerItems.find(si => si.link === el || si.lightboxLink === el);
    }

    private getItemByIdx(idx: number): IScrollerItem {
      if (idx < 0 || idx > this._scrollerItems.length -1) {
          throw new Error(`Out of bounds. Failed to invalidate controls`);
      }
      return this._scrollerItems[idx];
    }

    private invalidateItems = (): void => {
      this._scrollerItems = [];
      let idx: number = 0;
      this.items?.forEach((value: HTMLElement): void => {
        const targetKey: string | null = value.getAttribute('data-idx') || '';
        if (!this._scrollerItems.some(x => x.idx === +targetKey)) {
              
          const target: HTMLElement | null = this.gallery.querySelector(`.${EhGalleryController.GALLERY_PCITURE_ITEM_CLASSNAME}[data-idx='${targetKey}']`);
          const targetText: HTMLElement | null = this.gallery.querySelector(`.${EhGalleryController.GALLERY_PCITURE_TEXT_ITEM_CLASSNAME}[data-idx='${targetKey}']`);
          
          const lightboxLink: HTMLElement | null | undefined = this.lightbox?.querySelector(`.${EhGalleryController.GALLERY_NAVIGATION_ITEM_CLASSNAME}[data-idx='${targetKey}']`);
          const lightboxTarget: HTMLElement | null | undefined = this.lightbox?.querySelector(`.${EhGalleryController.GALLERY_PCITURE_ITEM_CLASSNAME}[data-idx='${targetKey}']`);
          const lightboxTargetText: HTMLElement | null | undefined = this.lightbox?.querySelector(`.${EhGalleryController.GALLERY_PCITURE_TEXT_ITEM_CLASSNAME}[data-idx='${targetKey}']`);
          
          const isDisabled: boolean = value.classList.contains(EhGalleryViewModel.DISABLED_CLASSNAME);
          if (!target || !targetText || isDisabled) {
              return;
          }
          this._scrollerItems.push({
              idx: idx++,
              link: value,
              target: target,
              targetText: targetText,

              lightboxLink: lightboxLink,
              lightboxTarget: lightboxTarget,
              lightboxTargetText: lightboxTargetText
          });
        }
      });
    }

    public invalidateControls = (scroller: HTMLElement): void => {
      const hideClass: string = EhGalleryViewModel.HIDE_CLASSNAME;

      this.previousButton?.forEach(item => {
        item.classList.add(hideClass);
      });
      this.nextButton?.forEach(item => {
        item.classList.add(hideClass);
      });
      if (this.isVertical) {
        if (scroller.scrollTop > 0 || scroller.clientHeight + scroller.scrollTop < scroller.scrollHeight) {
          this.previousButton?.forEach(item => {
            item.classList.remove(hideClass);
          });
          this.nextButton?.forEach(item => {
            item.classList.remove(hideClass);
          });
        }
      } else {
        if (scroller.scrollLeft > 0 || scroller.clientWidth + scroller.scrollLeft < scroller.scrollWidth) {
          this.previousButton?.forEach(item => {
            item.classList.remove(hideClass);
          });
          this.nextButton?.forEach(item => {
            item.classList.remove(hideClass);
          });
        }
      }
    }
  }
}
